import React from 'react';
import Layout from '../../layouts/Layout';
import JobAlerts from '../../components/JobAlerts/JobAlerts';
import { graphql, useStaticQuery } from 'gatsby';
import CTA from '../../components/CTA/CTA';
import HeroImage from '../../components/HeroImage/HeroImage';
import JobSearchForm from '../../components/JobSearchForm/JobSearchForm';
import teamHeroImg from '../../images/team-hero.png';
import TextSection from '../../components/TextSection/TextSection';
import TestimonialVideos from '../../components/TestimonialVideos/TestimonialVideos';
import Carousel from '../../components/Carousel/Carousel';

import carouselImage1 from '../../images/carouselImage1.jpg';
import carouselImage2 from '../../images/carouselImage2.jpg';
import carouselImage3 from '../../images/carouselImage3.jpg';
import carouselImage4 from '../../images/carouselImage4.jpg';
import carouselImage5 from '../../images/carouselImage5.jpg';
import carouselImage6 from '../../images/carouselImage6.jpg';
import carouselImage7 from '../../images/carouselImage7.jpg';

const TeamPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'About Us',
			href: '/',
			active: false
		},
		{
			text: 'Careers',
			href: '/search/',
			active: false
		},
		{
			text: 'Meet the Team',
			href: '/team/',
			active: false
		},
		{
			text: 'Working Here',
			href: '/work-here/',
			active: false
		}
	];
	const testimonials = [
		{
			videoURL: 'https://www.youtube.com/embed/VThf5ulo3MM',
			name: 'Paul',
			position: 'Design Engineering Manager',
			buttonText: 'Search Manager Jobs',
			buttonLink: '/search/?term=manager'
		},
		{
			videoURL: 'https://www.youtube.com/embed/4fsWjxhTt9w',
			name: 'Chas',
			position: 'Plant Supervisor',
			buttonText: 'Search Supervisor Jobs',
			buttonLink: '/search/?term=supervisor'
		},
		{
			videoURL: 'https://www.youtube.com/embed/I0J8URDrPHU',
			name: 'Bernard',
			position: 'Machine Operator',
			buttonText: 'Search Machine Operator Jobs',
			buttonLink: '/search/?term=operator'
		}
	];
	const carouselItems = [
		{
			imgSrc: carouselImage1
		},
		{
			imgSrc: carouselImage2
		},
		{
			imgSrc: carouselImage3
		},
		{
			imgSrc: carouselImage4
		},
		{
			imgSrc: carouselImage5
		},
		{
			imgSrc: carouselImage6
		},
		{
			imgSrc: carouselImage7
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Genpak Team'}
			desc={'Genpak Team'}
		>
			<HeroImage imgSrc={teamHeroImg} />
			<JobSearchForm
				data={data}
				h1={'Join a company where everyone is like family.'}
			/>
			<TextSection
				h2={'A Culture You Can Trust'}
				p={
					'Team-oriented culture. All Genpak values the hard work of its employees and strives to create collaborative, positive environment. We appreciate and value all Genpakers equally.'
				}
			/>
			<TestimonialVideos testimonials={testimonials} />
			<CTA
				h2={
					'We rely on great employees to ensure all containers meet the highest quality standards.'
				}
			/>
			<TextSection
				h2={'Beyond Job Responsibilities'}
				p={
					'We take pride in providing our team with an engaging and collaborative culture that extends past the typical work environment.\n' +
					'Join us in celebrating community and comradery!'
				}
			/>
			<Carousel items={carouselItems} />
			<CTA
				h2={'Ready to get started?'}
				p={
					'Genpak is growing and wants you to join our successful team today!'
				}
			/>
			<JobAlerts />
		</Layout>
	);
};

export default TeamPage;
