import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';

import './Carousel.css';

interface CarouselItems {
	items: Array<{
		imgSrc: string;
	}>;
}

const Carousel: React.FC<CarouselItems> = (props: CarouselItems) => {
	SwiperCore.use([Autoplay, Pagination, Navigation]);

	return (
		<section className={'Carousel px-3 pb-20'}>
			<div className={'container mx-auto'}>
				<Swiper
					modules={[Navigation, Pagination]}
					spaceBetween={50}
					loop
					className={'max-w-[980px]'}
					navigation
					pagination={{ clickable: true }}
					autoplay={{ delay: 3000 }}
					slidesPerView={1}
				>
					{props.items.map((item) => {
						return (
							<SwiperSlide
								className={'flex flex-col items-center pb-12'}
							>
								<img
									className={'max-w-[500px] rounded'}
									src={item.imgSrc}
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</section>
	);
};

export default Carousel;
