import * as React from 'react';

interface TestimonialVideosProps {
	testimonials: Array<{
		videoURL: string;
		name: string;
		position: string;
		buttonText: string;
		buttonLink: string;
	}>;
}

const TestimonialVideos: React.FC<TestimonialVideosProps> = (
	props: TestimonialVideosProps
) => {
	return (
		<section className={'px-3'}>
			{props.testimonials.map((test, index) => {
				return (
					<div
						className={
							'container mx-auto flex flex-col items-center justify-center'
						}
					>
						<div
							className={[
								'flex w-full max-md:flex-col',
								!(index % 2) ? 'flex-row' : 'flex-row-reverse'
							].join(' ')}
						>
							<div
								className={
									'flex w-1/2 flex-col items-center justify-center text-body max-md:w-full max-md:items-start'
								}
							>
								<div className={'max-md:py-8'}>
									<h3 className={'mb-1 font-lato text-3xl'}>
										Meet {test.name}
									</h3>
									<p className={'font-lato text-xl'}>
										{test.position}
									</p>
									<a href={test.buttonLink}>
										<button
											className={
												'mt-4 flex flex-row items-center rounded bg-gp-green px-7 py-3 font-lato text-xl font-medium text-white hover:bg-gp-green/[0.9] focus:bg-gp-green/[0.9]'
											}
										>
											{test.buttonText}
										</button>
									</a>
								</div>
							</div>
							<div
								className={'flex w-1/2 flex-col max-md:w-full'}
							>
								<iframe
									className={'aspect-video w-full'}
									src={test.videoURL}
									title="YouTube video player"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowFullScreen
								/>
							</div>
						</div>
					</div>
				);
			})}
		</section>
	);
};

export default TestimonialVideos;
